<template>
  <Sheet
    :value="value"
    :no-padding="true"
    has-footer
    width="650px"
    @input="$emit('input', false)"
  >
    <!-- title -->

    <template #title>Save Report</template>

    <!-- ... -->

    <template #default>
      <div id="document-save-details">
        <BaseScrollbar height="calc(100vh - 170px)">
          <div class="q-pl-md q-pr-md q-pb-md">
            <FormFieldLabel
              label="This report will be saved to your reports section. You can also add it to a dashboard for quick access. Stay organized and easily track your data insights!"
              transform=""
              class="q-mt-md"
            />
            <ValidationObserver ref="form">
              <div class="field-wrapper">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="Report Name"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="reportNmae"
                    label="Report Name"
                    is-mandatory
                    :error="errors[0]"
                    class="q-mb-sm"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-slot="{ errors }"
                  name="Description"
                  :rules="{ required: true }"
                >
                  <TextField
                    v-model="subject"
                    label="Description"
                    is-mandatory
                    :error="errors[0]"
                    class="q-mb-sm"
                  />
                </ValidationProvider>
                <div
                  class="q-mr-md q-mt-lg q-pb-sm"
                  style="border-bottom: 1px solid var(--divider-color)"
                >
                  Add this report to dashboard
                </div>

                <div class="">
                  <CheckboxField
                    v-model="selected"
                    label="Add To Dashboard"
                    description=""
                    class="q-mt-md"
                  />
                </div>

                <div
                  v-if="selected"
                  class="q-mr-md q-mt-lg q-pb-sm"
                  style="border-bottom: 1px solid var(--divider-color)"
                >
                  Who can access this report
                </div>
                <SingleChoiceField
                  v-if="selected"
                  :value="selecteduser"
                  :options="selectUser"
                  :options-per-line="2"
                  is-mandatory
                  :show-options-wrapper="false"
                  class="q-mt-md"
                  @input="updateuser"
                />

                <MultiSelectField
                  v-if="selected && selecteduser === 'selectuser'"
                  v-model="forwardUsers"
                  label="users"
                  is-mandatory
                  :options="userList"
                  class="field q-mt-md"
                />
              </div>
            </ValidationObserver>
          </div>
        </BaseScrollbar>
      </div>
    </template>

    <!-- footer -->

    <template #footer>
      <BaseButton
        is-flat
        label="cancel"
        class="q-mr-sm"
        @click="$emit('input', false)"
      />

      <BaseButton label="Save" :is-loading="isLoading" @click="saveFilter" />
    </template>

    <!-- ... -->
  </Sheet>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Sheet from "@/components/common/popup/Sheet.vue";
import TextField from "@/components/common/form/text-field/TextField";
import { dashboard } from "@/api/factory.js";
import SingleChoiceField from "@/components/common/form/single-choice-field/SingleChoiceField.vue";
import { user } from "@/api/factory.js";
import MultiSelectField from "@/components/common/form/select-field/MultiSelectField.vue";
import CheckboxField from "@/components/common/form/checkbox-field/CheckboxField.vue";
import FormFieldLabel from "@/components/common/form/FormFieldLabel.vue";
export default {
  name: "EmailSharing",

  components: {
    Sheet,
    ValidationObserver,
    ValidationProvider,
    TextField,
    SingleChoiceField,
    MultiSelectField,
    CheckboxField,
    FormFieldLabel,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    repositoryId: {
      type: Number,
      default: 0,
    },

    filter: {
      type: Array,
      default: () => [],
    },
    page: {
      type: String,
      default: "",
    },
    edit: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      reportNmae: "",
      subject: "",
      selected: false,
      selecteduser: "",
      selectUser: [
        {
          id: this.$nano.id(),
          label: "Private to me",
          description: "",
          value: "private",
        },
        {
          id: this.$nano.id(),
          label: "Select User",
          description: "",
          value: "selectuser",
        },
      ],
      userList: [],
      forwardUsers: [],
      userVale: [],
      isLoading: false,
    };
  },
  watch: {
    selected: {
      deep: true,
      immediate: true,
      handler() {
        if (this.selected === false) {
          this.userList = [];
          this.forwardUsers = [];
          this.selecteduser = "";
        }
      },
    },
    value: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.value) {
          await this.clearFields();
        }
      },
    },
    edit: {
      deep: true,
      immediate: true,
      async handler() {
        await this.clearFields();
        if (this.edit.length) {
          this.forwardUsers = [];
          let parse = JSON.parse(this.edit[0].assignTo);
          this.reportNmae = this.edit[0].name;
          this.subject = this.edit[0].description;
          this.selected = this.edit[0].dashboard;

          if (parse.length === 1) {
            this.selecteduser = "private";
          } else {
            this.selecteduser = "selectuser";
          }
          if (this.selecteduser === "selectuser") {
            await this.getUsers();
            this.forwardUsers = parse;
          }
        }
      },
    },
  },

  created() {
    // Example: Set minimumFromDate to today's date
  },

  methods: {
    clearFields() {
      this.userList = [];
      this.forwardUsers = [];
      this.selecteduser = {};
      this.selected = false;
      this.selecteduser = "";
      this.reportNmae = "";
      this.subject = "";
    },

    async saveFilter() {
      const isValid = await this.$refs.form.validate();

      if (!isValid) {
        return;
      }
      let filter = JSON.stringify(this.filter);
      let filtering = [
        {
          uId: this.repositoryId,
          filters: filter,
        },
      ];
      let filterss = JSON.stringify(filtering);
      let userId = this.$store.state.session.id;
      if (this.selecteduser === "selectuser") {
        this.userVale = this.forwardUsers;
      } else if (this.selecteduser === "private") {
        this.userVale = [userId];
      }
      this.isLoading = true;

      if (this.edit.length) {
        let input = {
          name: this.reportNmae,
          description: this.subject,
          filterJson: this.edit[0].filterJson,
          filterFrom: this.page,
          pinDashboard: this.selected,
          assignTo: JSON.stringify(this.userVale),
        };
        const { error } = await dashboard.deleteFilter(this.edit[0].id, input);
        this.isLoading = false;
        if (error) {
          this.$alert.warning("Editing error on Filter ");
          return;
        }
      } else {
        let input = {
          name: this.reportNmae,
          description: this.subject,
          filterJson: filterss,
          filterFrom: this.page,
          pinDashboard: this.selected,
          assignTo: JSON.stringify(this.userVale),
        };
        const { error } = await dashboard.saveDashboard(input);
        this.isLoading = false;
        if (error) {
          this.$alert.warning("The name already registered");
          return;
        }
      }

      this.$alert.success("The Filter Saved SuccessFully");
      this.clearFields();
      this.$emit("input", false);
      this.$emit("success");
    },

    updateuser(value) {
      this.selecteduser = value;
      this.$emit("update:selected", this.selecteduser);
      if (this.selecteduser === "selectuser") {
        this.getUsers();
      } else if (this.selecteduser === "private") {
        this.userList = [];
        this.forwardUsers = [];
      }
    },

    async getUsers() {
      this.userList = [];
      const { error, payload } = await user.getUserList();

      if (error) {
        this.$alert.error("Error fetching users");
        return;
      }
      if (payload) {
        payload.map((user) => {
          if (user.id)
            this.userList.push({
              id: this.$nano.id(),
              label: user.value,
              value: user.id,
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#document-save-details {
  .tabs {
    padding: 0px 16px;
    border-bottom: 1px solid var(--divider-color);
  }

  .secondary-hover:hover {
    background: $secondary-2 !important;
  }

  .close-icon {
    margin-left: 5px !important;
  }
}
</style>
